<template>
    <div>
        <div class="h3 mb-5 text-center">Liquidity pools</div>
        <b-card class="card-large">
            <div class="card-content">
                <b-row>
                    <b-col md="5" class="filters">
                        <div class="caption">
                            Filter by asset
                        </div>
                        <b-form-row>
                            <b-col>
                                <b-input placeholder="Token’s name" v-model="searchToken"></b-input>
                            </b-col>
                            <b-col class="flex-grow-0">
                                <button class="button-flat" v-on:click="filter()">Search</button>
                            </b-col>
                        </b-form-row>
                        <div class="caption mt-3">
                            Total amount of pools: {{ totalAmount }}
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <PoolsList
                                :pools = "getPoolsList"
                                scroll>
                        </PoolsList>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>

</template>

<script>
    export default {
        name: "LiquidityPools",
        components: {
            PoolsList: () => import("@/components/dashboard/PoolsList"),
        },
        data: function () {
            return {
                getPoolsList: [
                    { pair: ['ETH','DAI'], liquidity: '98,860,849', amount_symbol: '$', volumes_day: '4,346', volumes_week: '8,520', annual_rate: '0.9' },
                    { pair: ['TRX','DAI'], liquidity: '2,600,000', amount_symbol: '$', volumes_day: '21,554,529', volumes_week: '721,554,529', annual_rate: '78' },
                    { pair: ['ETH','EOS'], liquidity: '26,630,050', amount_symbol: '$', volumes_day: '49,536,020', volumes_week: '249,536,020', annual_rate: '250' },
                ],
                totalAmount: 0,
                searchToken: '',
            }
        },
    }
</script>

<style scoped>

</style>
